import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {getAuth, sendPasswordResetEmail} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import mainLogo from "../../images/MedBuddyLogo.png";
import useShowAlert from "../providers/AlertProvider/hook";

export default function SignIn() {
  const navigate = useNavigate();
  const {showAlert} = useShowAlert();
  const [email, setEmail] = React.useState("");

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      showAlert({
        severity: "success",
        message: "Success: Please check your email. You will receive an email if you have a valid account.",
      });
    } catch (e) {
      showAlert({
        severity: "error",
        message: e.code.toString() === "auth/invalid-email" ? "Error: Email not recognised." : e.message,
      });
    }
  };

  const handleChange = (event) => {
    event.target.name === "email" ? setEmail(event.target.value) : console.log("error");
  };

  return (<Container component="main" maxWidth="xs">
      <CssBaseline/>
      <Box
        sx={{
          marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center",
        }}
      >
        <h2 align="center"> Welcome to Med Buddy</h2>
        <img src={mainLogo} alt="ReactJs logo" width={170} height={170}/>
        <Typography component="h1" variant="h5" sx={{mt: 3}}>
          Forgot Password
        </Typography>
        <Box
          component="form"
          onSubmit={handleForgotPassword}
          validate="true"
          sx={{mt: 1}}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 3, mb: 2}}
          >
            Submit
          </Button>

          <Grid container>
            <Grid item xs></Grid>
            <Button
              onClick={() => {
                navigate("/");
              }}
            >
              <Link href="#" variant="body2">
                {"Back"}
              </Link>
            </Button>
          </Grid>
        </Box>
      </Box>
    </Container>);
}
