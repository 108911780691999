import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from "@mui/material/Link";

const UsageWarning = () => {

  return ([<Typography variant="body2" color="text.secondary" align="center" sx={{mt: 4}}>
      {'This information is intended for educational purposes only and should not be substituted for professional medical advice. Always consult with a qualified healthcare provider or local guidelines for usage in a clinical setting.'}
    </Typography>]);
}
export default UsageWarning;