// icon:stomach | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

function IconStomach(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M4 18c.67 1.85 2.07 4 8 4 2.36 0 5.07-.07 7-2 1-1 3-3 3-9s-2-7-4-7c-1.38 0-3 0-4 2v.03a1.82 1.82 0 01-1.87.92C11 6.81 11 6.37 11 6V2H9v4a2.92 2.92 0 003 3c1 0 1 1.78 1 3 0 1.89-.5 3.26-2 4-2.31 1.15-4.61 1-5.39-.53a1.5 1.5 0 00-2.47-.6A3.67 3.67 0 002 18v4h2z" />
    </svg>
  );
}

export default IconStomach;
