import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import EnhancedTable from "./Table";
import ResultsBox from "./Table/resultsBox";

export default function Databases() {
  const [selectedTopic, setSelectedTopic] = React.useState("");
  const [topic, setTopic] = React.useState('medications');
  const [topicData, setTopicData] = React.useState("");

  return (<Container component="main" maxWidth={"md"}>
    <CssBaseline/>
    <Box sx={{marginTop: 8}}>
      <h2 align="center">Knowledge Databases</h2>
    </Box>

    {!selectedTopic && <Box sx={{marginTop: 2, flexDirection: 'column', alignItems: 'center' }}>
      <EnhancedTable setSelectedTopic={setSelectedTopic} topic={topic} setTopic={setTopic} setTopicData={setTopicData}/>
    </Box>}

    {selectedTopic && <Box sx={{marginTop: 2, display: "flex", flexDirection: 'row', alignItems: 'top'}}>
      <EnhancedTable setSelectedTopic={setSelectedTopic} topic={topic} setTopic={setTopic} setTopicData={setTopicData}/>
      {selectedTopic && <ResultsBox selectedTopic={selectedTopic} topic={topic} topicData={topicData}/>}
    </Box>}

  </Container>);
}
