import React, {Component} from "react";

export default class Avatar extends Component {
  render() {
    return (<div className="avatar">
        <div className="avatar-img">
          {this.props.image === null || this.props.image === "null" ? <img src="/default.png" alt="#"/> :
            <img src={this.props.image} alt="#"/>}
        </div>
      </div>);
  }
}
