import * as React from "react";
import {useRef, useState} from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
  getAuth, createUserWithEmailAndPassword, updateProfile, sendEmailVerification,
} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {getStorage, ref, uploadBytesResumable} from "firebase/storage";
import PasswordStrengthBar from "react-password-strength-bar";
import mainLogo from "../../images/MedBuddyLogo.png";
import Avatar from "../chatList/Avatar";
import useShowAlert from "../providers/AlertProvider/hook";
import Copyright from "./copyright";

function makeId(length) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

async function handleFileUpload(user, filename, file) {
  const storage = getStorage();

  // Create the file metadata
  /** @type {any} */
  const metadata = {
    contentType: "image/jpeg",
  };

  // Upload file and metadata to the object 'images/mountains.jpg'
  const storageRef = ref(storage, user + "/profilePicture/" + makeId(5) + "." + filename.split(".").pop());
  const uploadTask = await uploadBytesResumable(storageRef, file, metadata);
  return ("https://firebasestorage.googleapis.com/v0/b/" + uploadTask.metadata.bucket + "/o/" + encodeURIComponent(uploadTask.metadata.fullPath) + "?alt=media");
}

export default function SignUp() {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [imageDisplay, setImageDisplay] = useState(null);
  const [imageName, setImageName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordScore, setPasswordScore] = useState("");
  const uploadInputRef = useRef(null);
  const {showAlert} = useShowAlert();

  const handleCapture = ({target}) => {
    setImageName(target.files[0].name);
    setImage(target.files[0]);
    setImageDisplay(URL.createObjectURL(target.files[0]));
  };

  function handlePassword(e) {
    setPassword(e.target.value);
  }

  function handlePasswordScore(score) {
    setPasswordScore(score);
  }

  function validateEmail(email) {
    let re = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (!validateEmail(data.get("email").toString())) {
      showAlert({severity: "error", message: "Please enter a valid email."});
    } else if (passwordScore < 3) {
      showAlert({
        severity: "error", message: "Please use a stronger password.",
      });
    } else {
      try {
        await createUserWithEmailAndPassword(getAuth(), data.get("email").toString(), data.get("password").toString());
        // Signed up
        const auth = getAuth();
        if (imageName) {
          const downloadUrl = await handleFileUpload(auth.currentUser.uid, imageName, image);
          await updateProfile(auth.currentUser, {
            displayName: data.get("fullname").toString(), photoURL: downloadUrl,
          });
        } else {
          await updateProfile(auth.currentUser, {
            displayName: data.get("fullname").toString(), photoURL: "null",
          });
        }
        const actionCodeSettings = {
          url: "https://app.medbuddy.com.au/?email=" + data.get("email"),
        };
        await sendEmailVerification(auth.currentUser, actionCodeSettings);
        showAlert({
          severity: "success", message: "Success: Check your email to verify email address before logging in.",
        });

        navigate("/");
      } catch (error) {
        showAlert({severity: "error", message: error.message});
      }
    }
  };

  return (<Container component="main" maxWidth="xs">
      <CssBaseline/>
      <Box
        sx={{
          marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center",
        }}
      >
        <h2 align="center"> Welcome to Med Buddy</h2>
        <img src={mainLogo} alt="ReactJs logo" width={170} height={170}/>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box
          component="form"
          validate="true"
          onSubmit={handleSubmit}
          sx={{mt: 3}}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="given-name"
                name="fullname"
                required
                fullWidth
                id="fullname"
                label="Full Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                onChange={(evt) => handlePassword(evt)}
              />
              <PasswordStrengthBar
                inputProps={{
                  name: "password_input", autoComplete: "off", className: "form-control",
                }}
                minLength={5}
                minScore={2}
                onChangeScore={(score) => {
                  handlePasswordScore(score);
                }}
                password={password}
              />
            </Grid>
            <Grid item xs={12}>
              <input
                /*className={classes.input}*/
                id="icon-button-photo"
                type="file"
                name="file"
                onChange={handleCapture}
                style={{display: "none"}}
                accept="image/png, image/jpeg"
                ref={uploadInputRef}
              />
              <Button
                fullWidth
                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
              >
                Upload Profile Picture (Optional)
              </Button>
              {imageDisplay && <Avatar image={imageDisplay}/>}
            </Grid>
            {/*              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid>*/}
          </Grid>
          <Typography variant="subtitle2" sx={{mt: 2}}>
            {'By signing up you agree to all of the terms which can be found can be found '}
            <Link color="inherit" href="/policies">
              here
            </Link>.
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 3, mb: 2}}
          >
            Agree & Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={(event) => {
                  navigate("/");
                }}
              >
                <Link href="#" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Button>
            </Grid>
          </Grid>
          <Copyright/>
        </Box>
      </Box>
    </Container>);
}
