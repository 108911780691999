// icon:pill | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

function IconPill(props) {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="currentColor"
      height="1.2em"
      width="1.2em"
      {...props}
    >
      <path d="M4.22 11.29l7.07-7.07a6.011 6.011 0 018.49 0c2.34 2.34 2.34 6.14 0 8.49l-7.07 7.07c-2.35 2.34-6.15 2.34-8.49 0a6.011 6.011 0 010-8.49m1.42 1.42a3.976 3.976 0 00-1.04 3.86l5.99-5.98 4.24 4.24 3.53-3.54a3.978 3.978 0 000-5.65 3.978 3.978 0 00-5.65 0l-7.07 7.07z" />
    </svg>
  );
}

export default IconPill;