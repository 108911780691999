import React, {Component} from "react";
import "./chatBody.css";
import ChatContent from "../chatContent/ChatContent";
import UserProfile from "../chatSidebar/UserProfile";
import AlertDialogSlide from "../chatSidebar/dialogBox";

export default class ChatBody extends Component {
  dialogContent = "";
  dialogType = "";
  dialogState = false;

  constructor(props) {
    super(props);
    this.state = {
      lastChatContent: {},
      dialogContent: this.dialogContent,
      dialogState: this.dialogState,
      dialogType: this.dialogType,
    };
  }

  onChange = () => {
    if (this.state.dialogState) {
      this.setState({dialogState: false});
      this.setState({dialogContent: ""});
      this.setState({dialogType: ""});
    } else {
      this.setState({dialogState: true});
    }
  };

  procedureDialog = (name) => {
    let link = "https://getdata-oxbzz5mipa-uc.a.run.app/?procedure=" + encodeURI(name)
    fetch(link, {
      headers: {
        'Access-Control-Allow-Origin': 'https://app.medbuddy.com.au'
      }, method: 'GET',
    })
      .then(function (response) {
        return response.text();
      })
      .then(jsonStr => {
        this.setState({dialogContent: jsonStr});
        this.setState({dialogType: "AI Procedure Lens"});
      });
    this.onChange();
  }

  conDialog = (name) => {
    let link = "https://getdata-oxbzz5mipa-uc.a.run.app/?condition=" + encodeURI(name)
    fetch(link, {
      headers: {
        'Access-Control-Allow-Origin': 'https://app.medbuddy.com.au'
      }, method: 'GET',
    })
      .then(function (response) {
        return response.text();
      })
      .then(jsonStr => {
        this.setState({dialogContent: jsonStr});
        this.setState({dialogType: "AI Condition Lens"});
      });
    this.onChange();
  }

  medDialog = (name) => {
    console.log(name);
    let link = "https://getdata-oxbzz5mipa-uc.a.run.app/?medication=" + encodeURI(name)
    fetch(link, {
      method: 'GET', headers: {
        'Access-Control-Allow-Origin': 'https://app.medbuddy.com.au'
      },
    })
      .then(function (response) {
        return response.text();
      })
      .then(jsonStr => {
        this.setState({dialogContent: jsonStr});
        this.setState({dialogType: "AI Medication Lens"});
      });
    this.onChange();
  }

  getInputResult = (lastChat) => {
    this.setState({lastChatContent: lastChat});
  }

  render() {
    return (<div className="main__chatbody">
        {/*<ChatList />*/}
        <ChatContent lastChatContent={this.state.lastChatContent} getInputResult={this.getInputResult}
                     procedureDialog={this.procedureDialog} medDialog={this.medDialog} conDialog={this.conDialog}/>
        <UserProfile lastChatContent={this.state.lastChatContent} medDialog={this.medDialog} conDialog={this.conDialog}
                     onChange={this.onChange} procedureDialog={this.procedureDialog}/>
        <AlertDialogSlide onChange={this.onChange} dialogState={this.state.dialogState}
                          dialogType={this.state.dialogType} dialogContent={this.state.dialogContent}/>
      </div>);
  }
}
