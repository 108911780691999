// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {initializeAppCheck, ReCaptchaV3Provider} from "firebase/app-check";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import { getRemoteConfig } from "firebase/remote-config";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAkTnbwwwUOFbRtr0hxJNYUok8x3deh8XI",
  authDomain: "med-buddy-firebase.firebaseapp.com",
  projectId: "med-buddy-firebase",
  storageBucket: "med-buddy-firebase.appspot.com",
  messagingSenderId: "588281295774",
  appId: "1:588281295774:web:7e207f1d17955de320dc8f",
  measurementId: "G-JFPC02PFQW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const remoteConfig = getRemoteConfig(app);

remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.settings.expirationDuration = 0;

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LdveMApAAAAAK6UD9f-qWF5CW46lKkX2-Gaqyok'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

