import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {getAuth, sendEmailVerification} from "firebase/auth";
import mainLogo from '../../images/MedBuddyLogo.png';
import useShowAlert from "../providers/AlertProvider/hook";


export default function ConfirmEmail() {
  const {showAlert} = useShowAlert();

  const handleSubmit = async (event) => {
    event.preventDefault();
    let auth = getAuth();

    let actionCodeSettings = {
      url: 'https://medbuddy.com.au/?email=' + auth.currentUser.email,
    };
    try {
      await sendEmailVerification(auth.currentUser, actionCodeSettings);
      showAlert({severity: "success", message: "Success: Check your email to verify email address before logging in."});
    } catch (e) {
      showAlert({severity: "error", message: e.message});
    }
  };

  return (<Container component="main" maxWidth="xs">
      <CssBaseline/>
      <Box
        sx={{
          marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',
        }}
      >
        <h2 align="center"> Welcome to Med Buddy.</h2>
        <img src={mainLogo} alt="ReactJs logo" width={170} height={170}/>
        <Typography component="h1" variant="h5" sx={{mt: 1}}>
          Please check your email and click verify. Once you have verified your email, please refresh this page.
        </Typography>
        <Box component="form" sx={{mt: 1}}>
          <Grid container>
            <Grid item xs>
              <Button variant="contained" onClick={handleSubmit} sx={{mr: 1}}>
                Resend Email
              </Button>
            </Grid>
            {/*              <Button variant="contained" onClick={() => {
                navigate('/chat')
              }}>
                Continue Once Verified
              </Button>*/}
          </Grid>
        </Box>
      </Box>
    </Container>);
}
