import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {Component} from "react";
import LinearProgress from '@mui/material/LinearProgress';
import Markdown from 'react-markdown'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class AlertDialogSlide extends Component {
  render() {
    return (<React.Fragment>
        {/*                <Button variant="outlined" onClick={this.props.onChange}>
                    Open Info
                </Button>*/}
        <Dialog
          open={this.props.dialogState}
          TransitionComponent={Transition}
          keepMountedonChange
          /*onClose={this.}*/
          aria-describedby="alert-dialog-slide-description"
        >
          {this.props.dialogType && <DialogTitle>{this.props.dialogType}</DialogTitle>}
          {!this.props.dialogType &&
            <DialogTitle>{"Loading... This may take some time if I am learning it for the first time."}</DialogTitle>}


          {this.props.dialogContent !== "" ? (<DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <Markdown>{this.props.dialogContent}</Markdown>
              </DialogContentText>
            </DialogContent>) : (<LinearProgress/>)}
          <DialogActions>
            <Button onClick={this.props.onChange}>Close</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>);
  }
}