import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase";
import {useNavigate} from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import AccountMenu from "./AccountMenu";
import AppMenu from "./AppMenu";

const ButtonAppBar = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  return (<Box sx={{flexGrow: 1}} className="nav-bar">
      <AppBar position="static">
        <Toolbar>
          {user && <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => {
              navigate('/')
            }}
            sx={{mr: 2}}
          >
            <HomeIcon/>
          </IconButton>}
          <Typography onClick={() => {
            navigate('/')
          }} variant="h6" component="div" sx={{flexGrow: 1, fontWeight: 'bold'}}>
            Med Buddy
          </Typography>
          {!user && [<Button onClick={() => {
            navigate('/contactUs')
          }} color="inherit">Contact Us</Button>]}
          {user && <AppMenu/> }
          {user && <AccountMenu/> }
        </Toolbar>
      </AppBar>
    </Box>);
}
export default ButtonAppBar;