import React from "react";
import Paper from "@mui/material/Paper";
import Markdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'
import Box from "@mui/material/Box";


export default function ResultsBox({topic, selectedTopic, topicData}) {
  return (<div className={"classes.root"}>
    <Paper className={"classes.root"} sx={{maxWidth: 500, minWidth: 500, marginLeft: 4}}>
      <Box sx={{padding: 1.5}}>
        <h2>{selectedTopic}</h2>
        <Markdown remarkPlugins={[remarkBreaks]}>{topicData.substring(topicData.indexOf("\n") + 1).split("**Disclaimer")[0]}</Markdown>
      </Box>
    </Paper>
  </div>);
}