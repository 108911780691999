import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useNavigate} from "react-router-dom";
import AppsIcon from '@mui/icons-material/Apps';
import ListItemIcon from "@mui/material/ListItemIcon";

export default function PositionedMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        color="inherit"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
          <AppsIcon color="white" fontSize="small" />
        {"Apps"}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => {navigate('/chat')}}>Chat</MenuItem>
        <MenuItem onClick={() => {navigate('/caseBuilder')}}>OSCE Case Builder</MenuItem>
        <MenuItem onClick={() => {navigate('/databases')}}>Knowledge Databases</MenuItem>
      </Menu>
    </div>
  );
}