import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {remoteConfig} from "../../../firebase";
import { ensureInitialized, fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';

// Utils
import {convertTruncateText} from "../utils";

// Table Contents
import {EnhancedTableHead} from "./Header";
import {EnhancedSearch} from "./Search";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import medList from "../../chatSidebar/medication_list.json";
import prodList from "../../chatSidebar/procedure_list.json";
import conList from "../../chatSidebar/conditions_list.json";
import Box from "@mui/material/Box";
import UsageWarning from "./usageWarning";
import Typography from "@mui/material/Typography";
import AdComponent from "../../googleAd/googleAd";

function descendingComparator(x, y, orderBy) {
  let a = x[orderBy];
  let b = y[orderBy];
  if (orderBy === "id") {
    a = Number(a);
    b = Number(b);
  }
  return b < a ? -1 : b > a ? 1 : 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function EnhancedTable({setSelectedTopic, topic, setTopic, setTopicData}) {
  /*const classes = useStyles();*/

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [tableData, setData] = React.useState(medList.medication_list);
  const [adRevenue, setAdRevenue] = React.useState(false);

  async function fetchConfig() {
    await fetchAndActivate(remoteConfig)
      .then(() => {
        let val = getValue(remoteConfig,'ad_revenue').valueOf()
        setAdRevenue(val._value)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const procedureDialog = (name) => {
    let link = "https://getdata-oxbzz5mipa-uc.a.run.app/?procedure=" + encodeURI(name)
    fetch(link, {
      headers: {
        'Access-Control-Allow-Origin': 'https://app.medbuddy.com.au'
      }, method: 'GET',
    })
      .then(function (response) {
        return response.text();
      })
      .then(jsonStr => {
        setTopicData(jsonStr);
      });
  }

  const conDialog = (name) => {
    let link = "https://getdata-oxbzz5mipa-uc.a.run.app/?condition=" + encodeURI(name)
    fetch(link, {
      headers: {
        'Access-Control-Allow-Origin': 'https://app.medbuddy.com.au'
      }, method: 'GET',
    })
      .then(function (response) {
        return response.text();
      })
      .then(jsonStr => {
        setTopicData(jsonStr);
      });
  }

  const medDialog = (name) => {
    let link = "https://getdata-oxbzz5mipa-uc.a.run.app/?medication=" + encodeURI(name)
    fetch(link, {
      method: 'GET', headers: {
        'Access-Control-Allow-Origin': 'https://app.medbuddy.com.au'
      },
    })
      .then(function (response) {
        return response.text();
      })
      .then(jsonStr => {
        setTopicData(jsonStr);
      });
  }

  const handleChange = (event) => {
    event.preventDefault(event);
    let selectorValue = event.target.value;
    setTopic(selectorValue);

    if (selectorValue === "medications") {
      setData(medList.medication_list)
    } else if (selectorValue === "procedures") {
      setData(prodList.medication_list)
    } else if (selectorValue === "conditions") {
      setData(conList.medication_list)
    }
  };

  React.useEffect(() => {
    if (tableData) setRows(tableData);
  }, [tableData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = async (event, id, message) => {
    await fetchConfig()
    setSelectedTopic(message);
    setSelected([id]);
    setTopicData("Loading....")
    if (topic === "medications") {
      medDialog(message);
    } else if (topic === "procedures") {
      procedureDialog(message);
    } else if (topic === "conditions") {
      conDialog(message);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (value) => selected.indexOf(value) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (<div className={"classes.root"}>
    <Paper className={"classes.root"} sx={{maxWidth: 900}}>
      <Box sx={{display: "flex", flexDirection: 'column', alignItems: 'left'}}>
        <FormControl sx={{minWidth: 100, paddingBottom: 1}}>
          <InputLabel id="demo-simple-select-label">Database</InputLabel>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={topic}
            label="Age"
            onChange={handleChange}
          >
            <MenuItem value={"medications"}>Medications</MenuItem>
            <MenuItem value={"procedures"}>Procedures</MenuItem>
            <MenuItem value={"conditions"}>Conditions</MenuItem>
          </Select>
        </FormControl>
        <EnhancedSearch
          rows={rows}
          setRows={setRows}
          originalRows={tableData}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
        />
      </Box>
      <Box>
        <TableContainer sx={{paddingTop: 3}} className={"classes.container"}>
          <Table
            className={"classes.table"}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              classes={'classes'}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />

            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (<TableRow
                    hover
                    onClick={(event) => {
                      handleClick(event, row.id, row.name);
                    }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      align="right"
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.id}
                    </TableCell>
                    <TableCell>
                      {convertTruncateText(row.name, 100)}
                    </TableCell>
                  </TableRow>);
                })}
              {emptyRows > 0 && (<TableRow style={{height: 33 * emptyRows}}>
                <TableCell colSpan={6}/>
              </TableRow>)}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, rows.length]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Box>
    </Paper>
    <UsageWarning/>
    {adRevenue === "true" && <Typography>.</Typography>}
    {adRevenue === "true" && <AdComponent></AdComponent>}
  </div>);
}