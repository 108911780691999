import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {getAuth} from "firebase/auth";


export default function DeleteChat() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const deleteChat = () => {
    const auth = getAuth();
    const uid = auth.currentUser.uid;

    let link = "https://deletechathistory-oxbzz5mipa-uc.a.run.app/?uid=" + uid
    fetch(link, {
      headers: {
        'Access-Control-Allow-Origin': 'https://app.medbuddy.com.au'
      }, method: 'GET',
    }).then(function (response) {
      handleClose()
    })
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (<React.Fragment>
      <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
        <Tooltip title="Chat settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ml: 2}}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Settings/>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="chat-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0, sx: {
            overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': {
              width: 32, height: 32, ml: -0.5, mr: 1,
            }, '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <MenuItem onClick={deleteChat}>
          <ListItemIcon>
            <DeleteForeverIcon fontSize="small"/>
          </ListItemIcon>
          Delete Chat History
        </MenuItem>
      </Menu>
    </React.Fragment>);
}