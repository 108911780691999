import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {Component} from "react";
import TextField from "@mui/material/TextField";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class AlertDialogPassword extends Component {
  render() {
    return (<React.Fragment>
        <Dialog
          open={this.props.dialogState}
          TransitionComponent={Transition}
          keepmountedonchange="true"
          /*onClose={this.}*/
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>Confirm Account Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please re-enter your password to confirm account deletion:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="password"
              label="Password"
              name="password"
              type="password"
              fullWidth
              variant="standard"
              value={this.props.password}
              onChange={this.props.handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onDialogueChange}>Close</Button>
            <Button onClick={this.props.handleDeleteAccount}>Submit</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>);
  }
}