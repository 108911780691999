import React, {Component, createRef} from "react";
import {auth, db} from "../../firebase";
import "./chatContent.css";
import Avatar from "../chatList/Avatar";
import ChatItem from "./ChatItem";
import BotChatItem from "./BotChatItem";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SendIcon from '@mui/icons-material/Send';
import Cookies from 'universal-cookie';
import AlertDialogUsage from "./dialogBoxUsage";
import DeleteChat from "./deleteChat";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {
  query, collection, orderBy, onSnapshot, limit, addDoc, serverTimestamp,
} from "firebase/firestore";
import Typography from "@mui/material/Typography";


let cookies = new Cookies();

export default class ChatContent extends Component {
  messagesEndRef = createRef(null);
  chatItms = [];

  username = cookies.get('username')
  userPhoto = cookies.get('userPhoto')
  dialogueState = cookies.get('usageDialogue') != null ? (cookies.get('usageDialogue').toString() !== "true") : true;

  constructor(props) {
    super(props);
    this.state = {
      chat: this.chatItms,
      msg: "",
      conditionsChat: [],
      medicationsChat: [],
      dialogState: this.dialogueState,
      lastChatState: "COMPLETED",
      detailedChecked: true,
      annotationsChecked: true,
      typingCount: 0
    };
  }

  onDialogueChange = () => {
    if (this.state.dialogState) {
      this.setState({dialogState: false});
      let cookies = new Cookies();
      cookies.set('usageDialogue', "true", {path: '/'});
    } else {
      this.setState({dialogState: true});
    }
  };
  scrollToBottom = () => {
    if (this.state.chat.length > 0) {
      this.messagesEndRef?.current.scrollIntoView({behavior: "smooth"});
    }
  };

  sendMessage = async () => {
    let message = this.state.msg;
    this.setState({msg: ""});
    this.setState({typingCount: 0});
    if (message !== "") {
      if (message.trim() === "") {
        alert("Enter valid message!");
        return;
      }
      const {uid, displayName, photoURL, email} = auth.currentUser;
      await addDoc(collection(db, "messages"), {
        prompt: message,
        name: displayName,
        avatar: photoURL,
        createdAt: serverTimestamp(),
        email: email,
        detailed: this.state.detailedChecked,
        uid,
      })
      /*this.getChatData(this.state.msg);*/
    }
  };

  componentDidMount() {
    const q = query(collection(db, "messages"), orderBy("createdAt", "desc"), limit(50));

    onSnapshot(q, (QuerySnapshot) => {
      if (auth.currentUser) {
        const uid = auth.currentUser.uid;
        const fetchedMessages = [];
        QuerySnapshot.forEach((doc) => {
          if (String(doc.data().uid) === String(uid)) {
            fetchedMessages.push({...doc.data(), id: doc.id});
          }
        })
        this.setState({
          chat: fetchedMessages.sort((a, b) => a.createdAt - b.createdAt)
        }, () => {
          this.scrollToBottom();
          if (fetchedMessages.length > 0) {
            this.setState({lastChatState: fetchedMessages[fetchedMessages.length - 1].state});
            this.props.getInputResult(fetchedMessages[fetchedMessages.length - 1]);
          }
        })
        this.setState({msg: ""});
      }
    });

    window.addEventListener("keydown", (e) => {
      if (window.location.pathname === "/chat" && e.keyCode == 13 && this.state.msg !== "") {
        this.sendMessage();
      }
    });
  }


  onStateChange = (e) => {
    this.setState({msg: e.target.value});
    this.setState({typingCount: e.target.value.length});
  };

  handleDetailedChange = (e) => {
    this.setState({detailedChecked: e.target.checked});
  };

  handleAnnotationChange = (e) => {
    this.setState({annotationsChecked: e.target.checked});
  };


  render() {
    return (<div className="main__chatcontent">
        <AlertDialogUsage dialogState={this.state.dialogState} onDialogueChange={this.onDialogueChange}/>
        <div className="content__header">
          <div className="blocks">
            <div className="current-chatting-user">
              <Avatar image={this.userPhoto}/>
              <p>{this.username}{this.state.dialogState}</p>
            </div>
          </div>

          <div className="blocks">
            <div className="settings">
              <FormGroup sx={{display: 'inline-block'}}>
                <FormControlLabel labelPlacement="start" label="Annotations" sx={{paddingBottom: 2}}
                                  control={<Switch checked={this.state.annotationsChecked}
                                                   onChange={this.handleAnnotationChange}/>}
                />
                <FormControlLabel labelPlacement="start" label="Detailed Response" sx={{paddingBottom: 2}}
                                  control={<Switch checked={this.state.detailedChecked}
                                                   onChange={this.handleDetailedChange}/>}
                />
                <button className="btn-nobg">
                  <DeleteChat className="fa fa-cog"/>
                </button>
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="content__body">
          <div className="chat__items">
            {this.state.chat.map((itm, index) => {
              return ((itm.response ? [<ChatItem
                  animationDelay={index + 2}
                  key={itm.id}
                  user={itm.name}
                  msg={itm.prompt}
                  image={itm.avatar}
                  time={itm.createdAt}
                  entity_array={itm.q_entity_array}
                  medDialog={this.props.medDialog}
                  conDialog={this.props.conDialog}
                  procedureDialog={this.props.procedureDialog}
                  annotations={this.state.annotationsChecked}
                />, <BotChatItem
                  animationDelay={index + 2}
                  key={itm.id + "chat"}
                  msg={itm.response}
                  time={itm.createdAt}
                  entity_array={itm.r_entity_array}
                  medDialog={this.props.medDialog}
                  conDialog={this.props.conDialog}
                  procedureDialog={this.props.procedureDialog}
                  annotations={this.state.annotationsChecked}
                />] : <ChatItem
                  animationDelay={index + 2}
                  key={itm.id}
                  user={itm.name}
                  msg={itm.prompt}
                  image={itm.avatar}
                  time={itm.createdAt}
                  entity_array={itm.q_entity_array}
                  medDialog={this.props.medDialog}
                  procedureDialog={this.props.procedureDialog}
                  conDialog={this.props.conDialog}
                  annotations={this.state.annotationsChecked}
                />));
            })}
            <div ref={this.messagesEndRef}/>
          </div>
        </div>
        <div className="content__footer">
          <div className="sendNewMessage">
            <button className="addFiles">
              <MoreHorizIcon className="fa fa-plus"
                             style={{display: "block", margin: "auto"}}/>
            </button>
            <input
              type="text"
              maxLength="800"
              placeholder="Type a message here"
              onChange={this.onStateChange}
              value={this.state.msg}
            />
            <Typography variant="body2" color="text.secondary" align="center" sx={{mt: 1, mr: 1}}>
              {this.state.typingCount}{"/800"}
            </Typography>
            {(this.state.lastChatState !== "STARTED" && this.state.lastChatState !== "PROCESSING") ? (
              <button className="btnSendMsg" id="sendMsgBtn" onClick={this.sendMessage}>
                <SendIcon className="fa fa-paper-plane"
                          style={{display: "block", margin: "auto"}}/>
              </button>) : <Box sx={{display: 'flex'}}> <CircularProgress/></Box>}
          </div>
        </div>
      </div>);
  }
}
