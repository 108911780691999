import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {db} from "../../firebase";
import {useNavigate} from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import mainLogo from '../../images/MedBuddyLogo.png';
import {addDoc, collection} from "firebase/firestore";
import useShowAlert from "../providers/AlertProvider/hook";

export default function SignIn() {
  const navigate = useNavigate();
  const {showAlert} = useShowAlert();
  const [formState, setFormState] = React.useState({contactType: "Other", name: "", email: "", message: ""});


  function validateEmail(email) {
    let re = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const name = formState.name;
    const email = formState.email;
    const content = formState.message;
    const contact_type = formState.contactType;

    if (!validateEmail(email)) {
      showAlert({severity: "error", message: "Please enter a valid email."});
    } else {
      try {
        await addDoc(collection(db, "mail"), {
          to: "medbuddyaus@gmail.com", message: {
            subject: "Feedback from Contact Form",
            text: "Name: " + name + "\n" + "Email: " + email + "\n" + "Contact Type: " + contact_type + "\n" + "Message: " + content + "\n"
            /*                html: "This is the <code>HTML</code> section of the email body.",*/
          },
        });
        showAlert({severity: "success", message: "Success: Request Sent! We will get back to you ASAP."});
        setFormState({contactType: "Other", name: "", email: "", message: ""})
      } catch (e) {
        showAlert({severity: "error", message: e.message});
      }
    }

  };

  const handleChange = (event) => {
    event.target.name === "name" ? setFormState({
      ...formState, // copy the current properties of "json"
      name: event.target.value // update the "name" property
    }) : event.target.name === "contactType" ? setFormState({
      ...formState, // copy the current properties of "json"
      contactType: event.target.value // update the "name" property
    }) : event.target.name === "email" ? setFormState({
      ...formState, // copy the current properties of "json"
      email: event.target.value // update the "name" property
    }) : event.target.name === "message" ? setFormState({
      ...formState, // copy the current properties of "json"
      message: event.target.value // update the "name" property
    }) : console.log("error")
  };

  return (<Container component="main" maxWidth="xs">
      <CssBaseline/>
      <Box
        sx={{
          marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',
        }}
      >
        <h2 align="center"> Contact Med Buddy Team</h2>
        <img src={mainLogo} alt="ReactJs logo" width={170} height={170}/>
        <Box component="form" onSubmit={handleSubmit} validate="true" sx={{mt: 1}}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            autoFocus
            value={formState.name}
            onChange={handleChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={formState.email}
            onChange={handleChange}
          />
          <FormControl sx={{mt: 1}} fullWidth required>
            <InputLabel id="demo-simple-select-filled-label">Contact Type</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              name="contactType"
              value={formState.contactType}
              label="Contact Type"
              onChange={handleChange}
            >
              <MenuItem value="Feature Request">Feature Request</MenuItem>
              <MenuItem value="General Inquiry">General Inquiry</MenuItem>
              <MenuItem value="Support">Support</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            required
            fullWidth
            multiline
            id="message"
            label="Message"
            name="message"
            autoFocus
            value={formState.message}
            onChange={handleChange}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 3, mb: 2}}
          >
            Submit
          </Button>
          <Grid container>
            <Grid item xs>
            </Grid>
            <Button onClick={() => {
              navigate('/')
            }}>
              <Link href="#" variant="body2">
                {"Back"}
              </Link>
            </Button>
          </Grid>
        </Box>
      </Box>
    </Container>);
}
