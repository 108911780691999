import React, {Component} from "react";
import "./userProfile.css";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InfoIcon from '@mui/icons-material/Info';
import medList from './medication_list.json';
import prodList from './procedure_list.json';
import conList from './conditions_list.json';
import mainLogo from '../../images/MedBuddyLogo.png';

export default class UserProfile extends Component {
  conditionsSearch = [];
  medicationsSearch = [];
  medicationsList = medList.medication_list;
  conditionsList = conList.medication_list;
  procedureList = prodList.medication_list;

  toggleInfo = (e) => {
    e.target.parentNode.classList.toggle("open");
  };

  constructor(props) {
    super(props);
    this.state = {
      conditionsSearch: this.conditionsSearch, medicationsSearch: this.medicationsSearch, dialogType: this.dialogType
    };
  }

  onCondInputChange = (event, value) => {
    if (value) {
      this.props.conDialog(value.name);
    }
  };

  onMedInputChange = (event, value) => {
    if (value) {
      this.props.medDialog(value.name)
    }
  };

  onProcedureInputChange = (event, value) => {
    if (value) {
      this.props.procedureDialog(value.name)
    }
  };

  render() {
    return (<div className="main__userprofile">
      <div className="profile__card user__profile__image">
        <div className="profile__image">
          <img src={mainLogo} alt="Main logo"/>
        </div>
        <h4>Hi, I'm Ollie 👋</h4>
        <p>Ask me medical questions or anything you wish!</p>
        <p>Search below to get a comprehensive overview of conditions and medications:</p>
      </div>

      <div className="profile__card">
        <div className="card__header">
          <InfoIcon/>
          <h4>Medical Condition Database</h4>
        </div>
        <Autocomplete
          id="combo-box-demo"
          clearOnEscape
          options={this.conditionsList}
          onChange={this.onCondInputChange}
          style={{width: 170, paddingBottom: "10px"}}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (<TextField {...params} label="Conditions" variant="outlined"/>)}
        />
      </div>
      <div className="profile__card">
        <div className="card__header">
          <InfoIcon/>
          <h4>Medications Database</h4>
        </div>
        <Autocomplete
          id="combo-box-demo"
          clearOnEscape
          options={this.medicationsList}
          onChange={this.onMedInputChange}
          style={{width: 170, paddingBottom: "10px"}}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (<TextField {...params} label="Medications" variant="outlined"/>)}
        />
      </div>
      <div className="profile__card">
        <div className="card__header">
          <InfoIcon/>
          <h4>Procedures/Pathology Test Database</h4>
        </div>
        <Autocomplete
          id="combo-box-demo"
          clearOnEscape
          options={this.procedureList}
          onChange={this.onProcedureInputChange}
          style={{width: 170, paddingBottom: "10px"}}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (<TextField {...params} label="Procedures" variant="outlined"/>)}
        />
      </div>
    </div>);
  }
}
