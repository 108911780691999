import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {Component} from "react";
import Link from "@mui/material/Link";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class AlertDialogUsage extends Component {
  render() {
    return (<React.Fragment>
        <Dialog
          open={this.props.dialogState}
          TransitionComponent={Transition}
          keepmountedonchange="true"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>Med Buddy Usage</DialogTitle>
          <DialogContent>
            <DialogContentText>
              The Med Buddy chat is for educational use only, to be used as a learning aid for students to guide
              further research using local guidelines and resources.
              It is not to be used for clinical decision making within a clinical environment.<br/><br/>
              {'Terms and Conditions of using Med Buddy can be found '}
              <Link color="inherit" href="/policies">
                here
              </Link>{'.'}<br/><br/>
              If you do not agree to all of the above, please logout.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onDialogueChange}>Agree</Button>
            <Button color="error" onClick={() => window.open('/signOut', "_self")}>Logout</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>);
  }
}