import React from 'react';
import ButtonAppBar from "./components/nav/NavBar";
import {useRoutes, Navigate, useLocation} from 'react-router-dom';
import SignIn from "./components/management/SignIn";
import Settings from "./components/settings/Settings";
import Signup from "./components/management/SignUp";
import ContactUs from "./components/management/ContactUs";
import ConfirmEmail from "./components/management/ConfirmEmail";
import ForgotPassword from "./components/management/ForgotPassword";
import CaseBuilder from "./components/caseBuilder/caseBuilder";
import Policies from "./components/policies/Policies";
/*import ResponsiveDrawer from "./components/chatv3/Demo";*/
import "./App.css";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import ChatBody from "./components/chatBody/ChatBody";
import Cookies from 'universal-cookie';
import {getAuth} from "firebase/auth";
import AlertProvider from './components/providers/AlertProvider/AlertProvider';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Databases from "./components/databases/Databases";

const defaultTheme = createTheme();
export const UserContext = React.createContext(null);

function RequireVerifiedEmail({children}) {
  let user = useAuthState(auth);
  let location = useLocation();
  if (user[0]) {
    getAuth().currentUser.reload()
    if (user[0].emailVerified === false) {
      return <Navigate to="/verifyemail" state={{from: location}}/>;
    }
    return children;
  } else {
    return <Navigate to="/" state={{from: location}}/>;
  }
}

function RequireAuth({children}) {
  let user = useAuthState(auth);
  let location = useLocation();

  if (user[0]) {
    if (user[0].emailVerified === false) {
      return <Navigate to="/verifyemail" state={{from: location}}/>;
    }
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    const cookies = new Cookies();
    cookies.set('username', user[0]['displayName'], {path: '/'});
    cookies.set('userPhoto', user[0]['photoURL'], {path: '/'});

    return <Navigate to="/chat" state={{from: location}}/>;
  }
  return children;
}

function SignOut({children}) {
  let cookies = new Cookies();
  cookies.set('usageDialogue', "false", {path: '/'});

  auth.signOut();
  return children;
}

const Display = () => {
  let routes = useRoutes([{
    path: '/', element: <RequireAuth>
      <SignIn/>
    </RequireAuth>
  }, {
    path: '/signOut', element: <SignOut>
      <Navigate to="/"/>
    </SignOut>
  }, {path: '/signup', element: <Signup/>}, {
    path: '/settings', element: <RequireVerifiedEmail>
      <Settings/>
    </RequireVerifiedEmail>
  }, {path: '/verifyEmail', element: <ConfirmEmail/>}, {
    path: '/chat', element: <RequireVerifiedEmail>
      <ChatBody/>
    </RequireVerifiedEmail>
  }, {path: '/databases', element: <RequireVerifiedEmail>
      <Databases/>
    </RequireVerifiedEmail>}
    , {path: '/contactUs', element: <ContactUs/>}
  , {
    path: '/caseBuilder', element: <RequireVerifiedEmail>
      <CaseBuilder/>
    </RequireVerifiedEmail>
  }, /*    {path: '/chatv3', element: <ResponsiveDrawer/>},*/
    {path: '/forgotPassword', element: <ForgotPassword/>}, {
      path: '*',
      skipLazyLoad: true,
      element: <Navigate to="/"/>
    }, {path: '/policies', element: <Policies/>}]);
  return routes;
};

class App extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      anchor: null
    };
  }

  render() {
    return (<ThemeProvider theme={defaultTheme}>
        <AlertProvider>
          <div className="App">
            {/*{window.location.pathname !== "/chatv3" && <ButtonAppBar/>}*/}
            <ButtonAppBar/>
            <Display/>
          </div>
        </AlertProvider>
      </ThemeProvider>);
  }
}

export default App;