import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import mainLogo from '../../images/MedBuddyLogo.png';
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Checkbox from '@mui/material/Checkbox';
import {FormControlLabel} from "@mui/material";

import {styled} from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';


const ListItem = styled('li')(({theme}) => ({
  margin: theme.spacing(0.5),
}));

export default function CaseBuilder() {
  const [formState, setFormState] = React.useState({
    difficulty: "", topic: "", output: "Waiting for input...", loading: false, conditionArray: []
  });

  const [chipData, setChipData] = React.useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const topic = formState.topic;
    const difficulty = formState.difficulty;

    setFormState({
      ...formState, // copy the current properties of "json"
      loading: true // update the "name" property
    })

    let link = "https://getdata-oxbzz5mipa-uc.a.run.app/?case=" + encodeURI(topic) + "&difficulty=" + difficulty
    fetch(link, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://app.medbuddy.com.au'
      }, method: 'POST', body: JSON.stringify({"conditions": chipData})
    }).then(function (response) {
      return response.json();
    }).then(function (myJson) {
      return myJson
    }).then(function (response) {
      setFormState({difficulty: "", topic: "", output: response.response, loading: false});
      return response.condition
    }).then(condition => {
      if (chipData.length < 5) {
        setChipData(oldArray => [...oldArray, {key: chipData.length, label: condition}]);
      }
    });
  };

  const handleChange = (event) => {
    event.target.name === "difficulty" ? setFormState({
      ...formState, // copy the current properties of "json"
      difficulty: event.target.value // update the "name" property
    }) : event.target.name === "topic" ? setFormState({
      ...formState, // copy the current properties of "json"
      topic: event.target.value // update the "name" property
    }) : console.log("error")
  };

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

  return (<Container component="main" maxWidth="xs">
      <CssBaseline/>
      <Box
        sx={{
          marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',
        }}
      >
        <h2 align="center"> Ai OSCE History Builder </h2>
        <img src={mainLogo} alt="ReactJs logo" width={170} height={170}/>
        <Typography component="h1" variant="h6" sx={{mr: 1}}>
          Select from the following topics:
        </Typography>
        <Box component="form" onSubmit={handleSubmit} validate="true" sx={{mt: 1}}>
          <FormControl sx={{mt: 1}} fullWidth required>
            <InputLabel id="demo-simple-select-filled-label">Topic</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              name="topic"
              value={formState.topic}
              label="Topic"
              onChange={handleChange}
            >
              <MenuItem value="Endocrinology">Endocrinology</MenuItem>
              <MenuItem value="Urology">Urology</MenuItem>
              <MenuItem value="Surgery">Surgery</MenuItem>
              <MenuItem value="Gastroenterology">Gastroenterology</MenuItem>
              <MenuItem value="General Medicine">General Medicine</MenuItem>
              <MenuItem value="Haematology">Haematology</MenuItem>
              <MenuItem value="Infectious Diseases">Infectious Diseases</MenuItem>
              <MenuItem value="Respiratory">Respiratory</MenuItem>
              <MenuItem value="Orthopaedics">Orthopaedics</MenuItem>
              <MenuItem value="Neurology">Neurology</MenuItem>
              <MenuItem value="Paediatrics">Paediatrics</MenuItem>
              <MenuItem value="Gynaecology">Gynaecology</MenuItem>
              <MenuItem value="Obstetrics">Obstetrics</MenuItem>
              <MenuItem value="Dermatology">Dermatology</MenuItem>
              <MenuItem value="Rheumatology">Rheumatology</MenuItem>
              <MenuItem value="Acute Medicine">Acute Medicine</MenuItem>
              <MenuItem value="Vascular">Vascular</MenuItem>
              <MenuItem value="Psychiatry">Psychiatry</MenuItem>
              <MenuItem value="Simulation">Simulation</MenuItem>
              <MenuItem value="Cardiology">Cardiology</MenuItem>
              <MenuItem value="Palliative Care">Palliative Care</MenuItem>
              <MenuItem value="Renal">Renal</MenuItem>
              <MenuItem value="Genetics">Genetics</MenuItem>
              <MenuItem value="Oncology">Oncology</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{mt: 1}} fullWidth required>
            <InputLabel id="demo-simple-select-filled-label">Difficulty</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              name="difficulty"
              value={formState.difficulty}
              label="Difficulty"
              onChange={handleChange}
            >
              <MenuItem value="easy">Easy</MenuItem>
              <MenuItem value="medium">Medium</MenuItem>
              <MenuItem value="hard">Hard</MenuItem>
            </Select>
          </FormControl>
          <Typography variant="subtitle2" sx={{mt: 1}}>
            Previously loaded conditions (will not repeat condition-max 5):
          </Typography>
          <Paper
            sx={{
              display: 'flex', justifyContent: 'center', flexWrap: 'wrap', listStyle: 'none', p: 0.5, m: 0, mt: 1,
            }}
            component="ul"
          >
            {chipData.map((data) => {
              return (<ListItem key={data.key}>
                  <Chip
                    label={data.label}
                    onDelete={handleDelete(data)}
                  />
                </ListItem>);
            })}
          </Paper>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{mt: 3, mb: 2}}
          >
            Submit
          </Button>
          <Typography component="h1" variant="h6" sx={{mr: 1}}>
            Output:
          </Typography>
          <Box component="div" sx={{whiteSpace: 'normal'}}>
            {formState.loading === false ? (formState.output.toString().split("\n").map(function (item, idx) {
                return (<span key={idx}>
                                        {(item.includes("###")) && [<br/>,
                                          <b className="user-message">{item.replace("### ", "")}</b>]}
                    {(!item.includes("###") && item !== "" && (item !== "Waiting for input...")) && [<br/>,
                      <FormControlLabel control={<Checkbox/>}
                                        label={item.replace("**", "\u2022 ").replace("** ", " ").replace("* ", "\u2022 ")}/>]}
                    {((item === "")) && <p className="user-message">{item}</p>}
                    {(item === "Waiting for input...") && item}
          </span>)
              })) : (<LinearProgress sx={{mt: 1}}/>)}
          </Box>
        </Box>
      </Box>
    </Container>);
}
