import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
/*import {GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {auth} from "../../firebase";*/
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import mainLogo from '../../images/MedBuddyLogo.png';
import Cookies from "universal-cookie";
import useShowAlert from "../providers/AlertProvider/hook";
import Copyright from "./copyright";

export default function SignIn() {
  const navigate = useNavigate();

  const {showAlert} = useShowAlert();

  function validateEmail(email) {
    let re = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const auth = getAuth();
    const data = new FormData(event.currentTarget);
    let cookies = new Cookies();
    cookies.set('usageDialogue', "false", {path: '/'});

    if (!validateEmail(data.get('email').toString())) {
      showAlert({severity: "error", message: "Please enter a valid email."});
    } else {
      try{
      await signInWithEmailAndPassword(auth, data.get('email').toString(), data.get('password').toString());
      showAlert({severity: "success", message: "Success: Logging in."});
    }catch (e) {
      showAlert({severity: "error", message: e.message});
      }
    }
  };

/*

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  };
*/

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h2 align="center"> Welcome to Med Buddy</h2>
          <img src={mainLogo} alt="ReactJs logo" width={170} height={170}/>
          <Typography component="h1" variant="h5" sx={{mt: 1}}>
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} validate="true" sx={{mt: 1}}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2}}

            >
              Sign In
            </Button>
            {/*            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 0, mb: 2 }}
                onClick={googleSignIn}
                startIcon={<Avatar src={'https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png'} />}
            >
              Sign In With Google
            </Button>*/}
            <Grid container>
              <Grid item xs>
                <Button onClick={() => {
                  navigate('/forgotPassword')
                }}>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Button>
              </Grid>
              <Button onClick={() => {
                navigate('/signUp')
              }}>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Button>
            </Grid>
            <Copyright/>
          </Box>
        </Box>
      </Container>
  );
}
