import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AlertContext from './AlertContext';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertProvider = ({children}) => {
  const [visible, setVisible] = React.useState(false);
  const [severity, setSeverity] = React.useState('');
  const [message, setMessage] = React.useState('');
  const showAlert = React.useCallback(params => {
    setVisible(true);
    setSeverity(params.severity);
    setMessage(params.message);
  }, []);

  const value = React.useMemo(() => ({
    showAlert,
  }), [showAlert]);

  return (<AlertContext.Provider value={value}>
      {children}
      <Stack spacing={2} sx={{width: '100%', position: 'absolute', left: 20, bottom: 20}}>
        <Snackbar open={visible} autoHideDuration={6000} onClose={() => setVisible(false)}>
          <Alert severity={severity} sx={{width: '100%'}}>
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </AlertContext.Provider>);
};

export default AlertProvider;