import React, {Component} from "react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {collection, onSnapshot, addDoc} from "firebase/firestore";
import {db} from "../../firebase";
import {getAuth} from "firebase/auth";

export default class ProductCard extends Component {

  async getCurrentUserSubscriptions(productId) {
    const auth = getAuth();
    const currentUser = auth.currentUser.uid;

    // Add a new document with a generated id.
    const docRef = await addDoc(collection(db, "customers", currentUser, "checkout_sessions"), {
      price: productId, success_url: window.location.origin, cancel_url: window.location.origin,
    });

    onSnapshot(docRef, (querySnapshot) => {
      const {error, url} = querySnapshot.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });

  }

  render() {
    return (<Card variant="outlined" sx={{minWidth: 275}}>
        <CardContent>
          <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
            {this.props.name}
          </Typography>
          <Typography variant="body2">
            {this.props.description}
          </Typography>
          <Typography variant="body2">
            Cost Per month (AUD): ${Number(this.props.price) * 0.01}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => {
            this.getCurrentUserSubscriptions(this.props.checkoutId)
          }}>Purchase</Button>
        </CardActions>
      </Card>);
  }
}
