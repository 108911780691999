import React from 'react';

export default class AdComponent extends React.Component {
  componentDidMount () {
/*    const installGoogleAds = () => {
      const elem = document.createElement("script");
      elem.src =
        "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
      elem.async = true;
      elem.defer = true;
      document.body.insertBefore(elem, document.body.firstChild);
    };
    installGoogleAds();*/
    if (window.adsbygoogle.length < 1) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }

  render () {
    return (
    <ins class="adsbygoogle"
         style={{display: 'block', textAlign: 'center'}}
         data-ad-format="fluid"
         data-ad-layout-key="-fb+5w+4e-db+86"
         data-ad-client="ca-pub-2108989191345253"
         data-ad-slot="4994541224"></ins>
    );
  }
}