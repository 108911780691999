import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import mainLogo from '../../images/MedBuddyLogo.png';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export default function SignIn() {
  const [open, setOpen] = React.useState(false);
  const [link, setLink] = React.useState('null');
  const [documentName, setDocumentName] = React.useState('null');

  const handleClickOpen = (Link, DocumentName) => () => {
    setOpen(true);
    setLink(Link);
    setDocumentName(DocumentName);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const {current: descriptionElement} = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (<Container component="main" maxWidth="xs">
      <CssBaseline/>
      <Box
        sx={{
          marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',
        }}
      >
        <h2 align="center"> Policies </h2>
        <Button
          onClick={handleClickOpen('https://app.termly.io/document/terms-of-service/367298a0-5c29-4d93-aad3-f20dcd30a8e3', 'Terms & Conditions')}>Terms
          & Conditions</Button>
        <Button
          onClick={handleClickOpen('https://app.termly.io/document/privacy-policy/0340f299-3eb8-48d8-83ed-f0f569a8144a', 'Privacy Policy')}>Privacy
          Policy</Button>
        <Button
          onClick={handleClickOpen('https://app.termly.io/document/cookie-policy/8f3dd890-47db-438a-aa38-99be69c46fef', 'Cookie Policy')}>Cookie
          Policy</Button>
        <Button
          onClick={handleClickOpen('https://app.termly.io/document/disclaimer/24b9c43d-e128-4192-b6c9-312435ebb844', 'Disclaimer')}>Disclaimer</Button>
        <Button
          onClick={handleClickOpen('https://app.termly.io/document/acceptable-use/c38d1478-357b-4f49-992c-8c36be95915c', 'Acceptable Use Policy')}>Acceptable
          Use Policy</Button>
        <Typography variant="body2" color="text.secondary" align="center" sx={{mt: 1}}>
          {"By using Med Buddy you agree to all of the above. If you have any questions regarding these terms, please use the 'contact us' portal."}
        </Typography>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">{documentName}</DialogTitle>
          <DialogContent>
            <iframe id="TermsIframe"
                    height="600" width="500"
                    src={link}>
            </iframe>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>);
}
