import React, {Component} from "react";
import Avatar from "../chatList/Avatar";
import TimeAgo from 'epoch-timeago';
import headLogo from '../../images/MedBuddyHead.png';
import Chip from "@mui/material/Chip";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import IconPill from './icons/pillIcon'
import IconStomach from "./icons/stomachIcon";
import IconBody from "./icons/bodyIcon";
import IconNeedle from "./icons/needleIcon";

const theme = createTheme({
  palette: {
    condition: {
      main: 'rgba(110,181,63,0.63)', contrastText: '#FFF'
    }, medication: {
      main: 'rgba(168,53,181,0.51)', contrastText: '#FFF'
    }, structure: {
      main: 'rgba(211,99,53,0.51)', contrastText: '#FFF'
    }, procedure: {
      main: 'rgba(51,195,165,0.51)', contrastText: '#FFF'
    },
  },
});

export default class ChatItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChange = (input, type) => {
    if (type === "MEDICATION") {
      this.props.medDialog(input)
    } else if (type === "DISEASE_DISORDER") {
      this.props.conDialog(input)
    } else if (type === "THERAPEUTIC_PROCEDURE") {
      this.props.procedureDialog(input)
    }
  };

  render() {
    return (<div
      style={{animationDelay: `0.8s`}}
      className={`chat__item chatbot`}
    >
      <div className="chat__item__content">
        <div className="chat__msg">
          <ThemeProvider theme={theme}>
            {(() => {
              let options = []
              let final_string = []
              let total_string = this.props.msg
              let used_words = []

              const splitAt = (index, xs) => [xs.slice(0, index), xs.slice(index)]
              const removeMD = (string) => {
                return string.replace(/\*\*/g, '').replace(/\*\s/g, '\u2022').replace(/\#\#\s/g, '').replace(/\#\#/g, '').replace(/\#/g, '')
              }

              const createChip = (entity) => {
                let final_string = []
                if (entity.entity_group === "DISEASE_DISORDER") {
                  final_string.push(<Chip size="small" icon={<IconStomach/>} color="condition" label={entity.word}
                                          onClick={() => this.onChange(entity.word, entity.entity_group)}/>)
                } else if (entity.entity_group === "MEDICATION") {
                  final_string.push(<Chip size="small" icon={<IconPill/>} color="medication" label={entity.word}
                                          onClick={() => this.onChange(entity.word, entity.entity_group)}/>)
                } else if (entity.entity_group === "THERAPEUTIC_PROCEDURE") {
                  final_string.push(<Chip size="small" icon={<IconNeedle/>} color="procedure" label={entity.word}
                                          onClick={() => this.onChange(entity.word, entity.entity_group)}/>)
                } else if (entity.entity_group === "BIOLOGICAL_STRUCTURE") {
                  final_string.push(<Chip size="small" icon={<IconBody/>} color="structure" label={entity.word}/>)
                } else {
                  final_string.push(<Chip label={entity.word}/>)
                }
                return final_string
              }

              if (this.props.entity_array && this.props.entity_array.length > 0 && this.props.annotations) {
                for (let h = 0; h <= this.props.entity_array.length - 1; h++) {
                  let split_array = []
                  if (h === 0) {
                    split_array = splitAt(this.props.entity_array[h].start, total_string)
                    final_string.push(removeMD((split_array[0])))
                    final_string.push(createChip(this.props.entity_array[h]))
                  } else {
                    split_array = splitAt(this.props.entity_array[h - 1].end, total_string)
                    split_array = splitAt(this.props.entity_array[h].start - this.props.entity_array[h - 1].end, split_array[1])
                    final_string.push(removeMD((split_array[0])))
                    if (!used_words.includes(this.props.entity_array[h].word.toLowerCase())) {
                      final_string.push(createChip(this.props.entity_array[h]))
                    } else {
                      final_string.push(removeMD(this.props.entity_array[h].word))
                    }
                  }
                  used_words.push((this.props.entity_array[h].word).toLowerCase())

                  if (h === this.props.entity_array.length - 1) {
                    split_array = splitAt(this.props.entity_array[h].end, total_string)
                    final_string.push(removeMD(split_array[1]))
                  }
                }
              } else {
                final_string.push(removeMD(total_string))
              }
              options.push([<span key={1}><p className="user-message">{final_string}</p></span>]);
              return options
            })()}
          </ThemeProvider>
        </div>
        <div className="chat__meta">
          <span>{TimeAgo(this.props.time * 1000)}</span>
        </div>
      </div>
      <Avatar image={headLogo}/>
    </div>);
  }
}