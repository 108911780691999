import {useContext} from 'react';
import AlertContext from './AlertContext';

const useShowAlert = () => {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error('useShowAlert must be within AlertProvider');
  }
  return context;
};

export default useShowAlert;