import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from "@mui/material/Link";

const Copyright = () => {

  return ([<Typography variant="body2" color="text.secondary" align="center" sx={{mt: 4}}>
      {'Works best on web browser. Sign-up only available through approved university emails.'}
    </Typography>, <Typography variant="body2" color="text.secondary" align="center" sx={{mt: 1}}>
      {'Copyright © '}
      <Link color="inherit" href="https://medbuddy.com.au/">
        MedBuddy
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>, <Typography variant="body2" color="text.secondary" align="center" sx={{mt: 1}}>
      {'Terms and Conditions of using Med Buddy can be found '}
      <Link color="inherit" href="/policies">
        here
      </Link>{'.'}
    </Typography>]);
}
export default Copyright;