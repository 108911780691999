import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {getAuth, reauthenticateWithCredential, sendPasswordResetEmail, EmailAuthProvider} from "firebase/auth";
import mainLogo from '../../images/MedBuddyLogo.png';
import AlertDialogPassword from "./dialogBoxPassword";
import {auth, db} from "../../firebase";
import {collection, query, where, onSnapshot, getDocs} from "firebase/firestore";
import {Component} from "react";
import ProductCard from "./ProductCard";


export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSnackbar: false,
      errorMessage: "",
      errorType: "",
      subscription: "",
      role: "",
      products: [],
      password: "",
      dialogStatePasswordChange: false,
      dialogState: false,
      newPassword: false,
      hasResetPassword: false,
      hasDeletedAccount: false
    };
  }

  onDialogueChange = () => {
    if (this.state.dialogState) {
      this.setState({dialogState: false});
    } else {
      this.setState({dialogState: true});
    }
  };

  handleDeleteAccount = () => {
    this.onDialogueChange();
    const credential = EmailAuthProvider.credential(auth.currentUser.email, this.state.password)
    reauthenticateWithCredential(auth.currentUser, credential)
      .then(function () {
        auth.currentUser.delete().then(() => {
          this.setState({hasDeletedAccount: "Account Deleted."})
        });
      }).catch((error) => {
      this.setState({hasDeletedAccount: "Incorrect password!"})
    });
  };

  handleChangePassword = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    sendPasswordResetEmail(auth, user.email).then(() => {
      this.setState({hasResetPassword: "Check your email for a reset link. This may take a few minutes."})
    }).catch((error) => {
      this.setState({hasResetPassword: "Oops, something went wrong. Please try again later."})
    });
  };

  openInNewTab(url) {
    window.open(url, '_blank').focus();
  }

  async getCustomClaimRole() {
    await auth.currentUser.getIdToken(true);
    const decodedToken = await auth.currentUser.getIdTokenResult();
    this.setState({role: decodedToken.claims.stripeRole});
    return decodedToken.claims.stripeRole;
  }

  getProducts() {
    let productArray = []

    const query1 = query(collection(db, "products"), where('active', '==', true));

    onSnapshot(query1, async (QuerySnapshot) => {
      QuerySnapshot.forEach(function (docs) {
        let product = docs.data();
        if (product.metadata.firebaseDisplay !== "False") {
          product['id'] = docs.id;
          productArray.push(product);
        }
      });
      for (const product of productArray) {
        let docReF = collection(db, "products", product.id, "prices");
        let qSnap = await getDocs(docReF);
        product['prices'] = qSnap.docs.map(d => ({id: d.id, ...d.data()}));
      }
      this.setState({products: productArray});
    });
  }

  getCurrentUserSubscriptions(currentUser) {
    const query2 = query(collection(db, "customers", currentUser, "subscriptions"), where('status', 'in', ['trialing', 'active']));

    onSnapshot(query2, async (QuerySnapshot) => {
      if (!QuerySnapshot.docs[0]) {
        let subscription_string = 'You have not signed for a subscription. The default subscription is "Med Buddy Free", giving you access to 50 questions per day during the trial period.';
        this.setState({subscription: subscription_string});
      } else {
        const subscription = QuerySnapshot.docs[0].data();
        let priceData = subscription.items[0].price;

        let subscription_string = "You are paying $" + priceData.unit_amount + " " + priceData.currency.toUpperCase() + " per " + priceData.interval + ", giving you the role: " + await this.getCustomClaimRole() + ".";

        this.setState({subscription: subscription_string});
      }
    });
  }

  handleChange = (event) => {
    event.target.name === "password" ? this.setState({password: event.target.value}) : console.log("error")
  };

  componentDidMount() {
    const auth = getAuth();
    const currentUser = auth.currentUser.uid;
    this.getProducts();
    this.getCurrentUserSubscriptions(currentUser);
  }

  render() {
    return (<>
      <AlertDialogPassword onDialogueChange={this.onDialogueChange} handleChange={this.handleChange}
                           handleDeleteAccount={this.handleDeleteAccount} dialogState={this.state.dialogState}
                           password={this.state.password}/>
      <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <Box
          sx={{
            marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',
          }}
        >
          <h2 align="center"> Settings</h2>
          <img src={mainLogo} alt="ReactJs logo" width={170} height={170}/>
        </Box>
        <hr/>
        <Box component="form" sx={{mt: 1, mb: 1}}>
          <Grid container>
            <Typography component="h1" variant="h6" sx={{mr: 1}}>
              My Subscription
            </Typography>
            <Typography component="h1" variant="body2" sx={{mr: 1}}>
              {this.state.subscription}
            </Typography>
          </Grid>
        </Box>
        <hr/>
        {this.state.role && <Box component="form" sx={{mt: 1, mb: 1}}>
          <Grid container>
            <Typography component="h1" variant="h6" sx={{mr: 1}}>
              Manage Subscription
            </Typography>
            <Typography component="h1" variant="subtitle1" sx={{mr: 1}}>
              View invoices, update subscription & payment methods
            </Typography>
            <Button variant="outlined" onClick={() => {
              this.openInNewTab("https://billing.stripe.com/p/login/fZeeXg2dw9sFbMk144?prefilled_email=" + encodeURIComponent(getAuth().currentUser.email))
            }}>
              Access customer portal
            </Button>
          </Grid>
        </Box>}
        {this.state.role && <hr/>}
        {(!this.state.role) && <Box component="form" sx={{mt: 1, mb: 1}}>
          <Grid container>
            <Typography component="h1" variant="h6" sx={{mr: 1}}>
              Available Subscriptions
            </Typography>
          </Grid>
          {this.state.products.map((itm, index) => {
            return (<ProductCard
              key={itm.id}
              name={itm.name}
              description={itm.description}
              image={itm.images[0]}
              price={itm.prices[0].unit_amount}
              checkoutId={itm.prices[0].id}
            />);
          })}
          {(this.state.products.length === 0) && "There are no subscriptions currently available."}
        </Box>}
        {(!this.state.role) && <hr/>}
        <Box component="form" sx={{mt: 1, mb: 1}}>
          <Grid container>
            <Grid item xs>
              <Typography component="h1" variant="h6" sx={{mr: 1}}>
                Update your password
              </Typography>
            </Grid>
            <Button variant="outlined" onClick={() => {
              this.handleChangePassword()
            }}>
              Update
            </Button>
            {(this.state.hasResetPassword) && <Typography variant="body2" color="text.secondary" sx={{mt: 2}}>
              {this.state.hasResetPassword}
            </Typography>}
          </Grid>
        </Box>
        <hr/>
        <Box component="form" sx={{mt: 1}}>
          <Grid container>
            <Grid item xs>
              <Typography component="h1" variant="h6" sx={{mr: 1}}>
                Delete Account
              </Typography>
            </Grid>
            <Button variant="outlined" color="error" onClick={() => {
              this.onDialogueChange()
            }}>
              Delete
            </Button>
          </Grid>
          {(this.state.hasDeletedAccount) && <Typography variant="body2" color="text.secondary" sx={{mt: 1}}>
            {this.state.hasDeletedAccount}
          </Typography>}
        </Box>
      </Container>
    </>);
  }
}
