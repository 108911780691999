import React from "react";
import TextField from "@mui/material/TextField";

function EnhancedSearch({setPage, setRowsPerPage, setRows, originalRows}) {
  const [searched, setSearched] = React.useState("");

  const requestSearch = (event) => {
    let searchedVal = event.target.value
    setSearched(searchedVal)
    const filteredRows = originalRows.filter((row) => {
      return row.name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
    /*setRowsPerPage(filteredRows.length);*/
    setPage(0);
  };

  return (<TextField value={searched} onChange={requestSearch} id="outlined-basic" label="Search" variant="outlined"/>);
}

export {EnhancedSearch};
